import store from '@/store/vuex'
import { Module, VuexModule, Mutation, getModule } from 'vuex-module-decorators'
import { CapaRegraImposto } from '@/models'

@Module({ name: 'RegraDeImpostoStore',
	namespaced: true,
	dynamic: true,
	store,
})
export default class RegraDeImpostoStore extends VuexModule {
	regras: CapaRegraImposto[] = []

	@Mutation
	adicionarRegra(regra: CapaRegraImposto) {
		this.regras.push(regra)
	}

	@Mutation
	atualizarRegra(regra: CapaRegraImposto) {
		const regraIndex = this.regras.findIndex(element => element.id === regra.id)
		this.regras.splice(regraIndex, 1, regra)
	}

	@Mutation
	setRegras(regras: CapaRegraImposto[]) {
		this.regras = regras
	}

	@Mutation
	removerRegra(regra: CapaRegraImposto) {
		this.regras = this.regras.filter(element => element.id !== regra.id)
	}

	get regrasDeImposto() {
		return this.regras
	}
}
export const RegraDeImpostoModule = getModule(RegraDeImpostoStore)
